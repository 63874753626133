<template>
  <div>
    <div class="banner-warp">
      <div class="mask d-flex align-center justify-center">
        <h2 class="text-h2 my-5 font-weight-black white--text">よくある質問</h2>
      </div>
    </div>
    <v-card height="500" flat class="d-flex align-center justify-center">
      <h1 class="text-h3 grey--text">暫くQ＆A情報はありません</h1>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped lang="stylus"></style>
